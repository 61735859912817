// App.js

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  createContext,
  useContext,
} from 'react';
import debounce from 'lodash.debounce';
import { io } from 'socket.io-client';
import './App.css';

// Helper functions
const shortenAddress = (address, isTokenAddress = false) => {
  if (!address) return 'N/A';
  if (isTokenAddress) return `${address.slice(0, 6)}..${address.slice(-4)}`;
  else if (window.innerWidth <= 768)
    return `${address.slice(0, 6)}..${address.slice(-4)}`;
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

// Helper function to format big numbers and truncate to two decimals
const formatBigNumber = (numberString, decimals = 9) => {
  if (!numberString) return 'N/A';
  const num = parseFloat(numberString) / Math.pow(10, decimals);
  return num.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

// Define API base URL
const API_BASE_URL = 'https://tokens.suifox.com'; // Replace with your backend URL

// Create a context for current time
export const CurrentTimeContext = createContext(Date.now());

function App() {
  const [tokens, setTokens] = useState([]);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState(Date.now()); // Add currentTime state

  // Refs to manage mutable values without causing re-renders
  const pageRef = useRef(1);
  const hasMoreRef = useRef(true);
  const loadingRef = useRef(false);

  // Initialize Socket.io
  useEffect(() => {
    console.log('API_BASE_URL:', API_BASE_URL);

    const socket = io(API_BASE_URL, {
      path: '/socket.io',
      transports: ['websocket'],
      withCredentials: true,
    });

    socket.on('connect', () => {
      console.log('Connected to Socket.io server');
    });

    socket.on('newToken', (token) => {
      console.log('Received new token via Socket.io:', token);
      setTokens((prevTokens) => {
        const existingTokenIndex = prevTokens.findIndex((t) => t.id === token.id);
        if (existingTokenIndex !== -1) {
          // Update the existing token
          const newTokens = [...prevTokens];
          newTokens[existingTokenIndex] = token;
          return newTokens;
        } else {
          // Add the new token at the beginning
          return [token, ...prevTokens];
        }
      });
    });

    socket.on('updatedToken', (updatedToken) => {
      console.log('Received updated token via Socket.io:', updatedToken);
      setTokens((prevTokens) => {
        const existingTokenIndex = prevTokens.findIndex((t) => t.id === updatedToken.id);
        if (existingTokenIndex !== -1) {
          // Update the existing token
          const newTokens = [...prevTokens];
          newTokens[existingTokenIndex] = updatedToken;
          return newTokens;
        } else {
          // Token not found in current list
          return prevTokens;
        }
      });
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from Socket.io server');
    });

    // Cleanup on unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  // Fetch tokens function
  const fetchTokens = useCallback(async () => {
    if (loadingRef.current || !hasMoreRef.current) {
      console.log(
        'Fetch aborted: loadingRef.current =',
        loadingRef.current,
        'hasMoreRef.current =',
        hasMoreRef.current
      );
      return;
    }

    loadingRef.current = true;
    console.log(`Fetching tokens for page ${pageRef.current}`);

    try {
      const response = await fetch(
        `${API_BASE_URL}/tokens?page=${pageRef.current}&limit=5`,
        {
          credentials: 'include',
        }
      );
      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      console.log(`Fetched ${data.length} tokens for page ${pageRef.current}`);

      if (data.length === 0) {
        hasMoreRef.current = false;
        console.log('No more tokens to load.');
      } else {
        setTokens((prevTokens) => {
          // Merge fetched tokens with existing tokens, avoiding duplicates
          const tokenMap = new Map();
          [...prevTokens, ...data].forEach((token) => {
            tokenMap.set(token.id, token);
          });
          return Array.from(tokenMap.values()).sort(
            (a, b) => b.timestampMs - a.timestampMs
          );
        });
        pageRef.current += 1;
      }
    } catch (err) {
      console.error('Failed to fetch tokens:', err);
      setError('Failed to load tokens.');
    } finally {
      loadingRef.current = false;
    }
  }, []);

  // Initial fetch
  useEffect(() => {
    fetchTokens();
  }, [fetchTokens]);

  // Debounced infinite scrolling handler
  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
      const threshold = document.documentElement.scrollHeight - 100;

      if (
        scrollPosition >= threshold &&
        !loadingRef.current &&
        hasMoreRef.current
      ) {
        console.log('Near bottom, fetching next page...');
        fetchTokens();
      }
    }, 200);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchTokens]);

  // Update currentTime every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Platform icons mapping
  const platformIcons = {
    movepump: '/movepump.svg',
    'turbos.fun': '/turbos.svg',
    // Add other platforms as needed
  };

  return (
    <CurrentTimeContext.Provider value={currentTime}>
      <div className="full-width-wrapper">
        <header className="sticky-header">
          <img src="/logo.svg" alt="Logo" className="header-logo" />
          <div className="header-buttons">
            <a
              href="https://twitter.com/SuifoxOfficial"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <img src="/twitter.svg" alt="Twitter" className="social-icon" />
            </a>
            <a
              href="https://t.me/SuifoxOfficial"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Telegram"
            >
              <img src="/telegram.svg" alt="Telegram" className="social-icon" />
            </a>
            <div className="tooltip">
              <button
                onClick={(e) => e.preventDefault()}
                className="buy-suifox-button"
                style={{ textDecoration: 'none' }}
              >
                Connect Wallet
              </button>
              <span className="tooltiptext">Coming Soon</span>
            </div>
          </div>
        </header>

        <div className="app-container">
          <img src="/logo.svg" alt="Logo" className="main-logo" />
          <h1 className="beta-header">Suifox</h1>
          <h1 className="analytics-header">Token Sniffer</h1>
          {tokens.length === 0 ? (
            loadingRef.current ? (
              <p>Loading...</p>
            ) : (
              <p>No tokens found.</p>
            )
          ) : (
            <ul className="token-list">
              {tokens.map((token) => (
                <TokenCard
                  key={token.id}
                  token={token}
                  platformIcons={platformIcons}
                />
              ))}
            </ul>
          )}
          {loadingRef.current && tokens.length > 0 && <p>Loading more tokens...</p>}
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {!hasMoreRef.current && tokens.length > 0 && <p>No more tokens to load.</p>}
        </div>

        <footer className="footer">
          <div className="footer-content">
            <div className="footer-left">
              <p>&copy; Suifox 2024</p>
              <p>
                Made by{' '}
                <a
                  href="https://x.com/0xr4bb1tz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  0xR4bb1tz
                </a>
              </p>
            </div>
            <div className="footer-right">
              <ul className="footer-links">
                <li>
                  <a
                    href="https://twitter.com/SuifoxOfficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/SuifoxOfficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Telegram
                  </a>
                </li>
                <li>
                  <a
                    href="https://suifox.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Analytics
                  </a>
                </li>
                <li>
                  <a
                    href="https://suifox.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Token
                  </a>
                </li>
                <li>
                  <a
                    href="https://suifox.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Docs
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </CurrentTimeContext.Provider>
  );
}

// TimeAgo component
const TimeAgo = ({ timestamp }) => {
  const currentTime = useContext(CurrentTimeContext);

  const timeString = useMemo(() => {
    if (!timestamp) return 'N/A';
    const diff = Math.floor((currentTime - timestamp) / 1000);
    if (diff < 60) return `${diff}s ago`;
    if (diff < 3600) return `${Math.floor(diff / 60)}m ago`;
    if (diff < 86400) return `${Math.floor(diff / 3600)}h ago`;
    return `${Math.floor(diff / 86400)}d ago`;
  }, [timestamp, currentTime]);

  return <>{timeString}</>;
};

// CircularProgress component
const CircularProgress = ({ percentage, imageSrc, altText }) => {
  const strokeWidth = 4; // Reduced stroke width for thinner borders
  const size = 100;
  const padding = strokeWidth; // Padding to accommodate stroke widths
  const viewBoxSize = size + padding * 2; // Total size including padding
  const center = viewBoxSize / 2;
  const radius = size / 2;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (percentage / 100) * circumference;

  return (
    <div className="circular-progress">
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        preserveAspectRatio="xMidYMid meet"
      >
        {/* Outer Border */}
        <circle
          className="progress-outer-border"
          stroke="#005050" // Dark teal color
          strokeWidth={strokeWidth / 2}
          fill="none"
          cx={center}
          cy={center}
          r={radius + strokeWidth / 2}
        />
        {/* Background Circle */}
        <circle
          className="progress-background"
          stroke="rgba(0, 179, 179, 0.2)"
          strokeWidth={strokeWidth}
          fill="none"
          cx={center}
          cy={center}
          r={radius}
        />
        {/* Progress Bar */}
        <circle
          className="progress-bar"
          stroke="#00e0e0"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          fill="none"
          cx={center}
          cy={center}
          r={radius}
          strokeDasharray={circumference}
          strokeDashoffset={progress}
        />
        {/* Inner Border */}
        <circle
          className="progress-inner-border"
          stroke="#005050" // Dark teal color
          strokeWidth={strokeWidth / 2}
          fill="none"
          cx={center}
          cy={center}
          r={radius - strokeWidth / 2}
        />
      </svg>
      <img
        src={imageSrc}
        alt={altText}
        className="circular-progress-image"
      />
    </div>
  );
};

// TokenCard component
const TokenCard = React.memo(
  ({ token, platformIcons }) => {
    // Normalize the platform key
    const platformKey = token.platform.toLowerCase();

    // Helper function to format URLs
    const formatUrl = (url) => {
      if (!url) return '';
      return url.match(/^https?:\/\//i) ? url : `https://${url}`;
    };

    return (
      <li className="token-card">
        {/* Token Header */}
        <div className="token-header">
          <div className="token-image-name">
            {token.uri && (
              <CircularProgress
                percentage={token.bondingCurvePercentage || 0}
                imageSrc={token.uri}
                altText={`${token.name} logo`}
              />
            )}
            <div className="token-info">
              <div className="token-name">{token.name}</div>
              <div className="token-symbol">{token.symbol}</div>
              {/* Social Links */}
              {(token.website || token.twitter || token.telegram) && (
                <div className="social-links">
                  {/* Website Icon */}
                  {token.website && (
                    <a
                      href={formatUrl(token.website)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon-link"
                    >
                      <img
                        src="/website.svg"
                        alt="Website"
                        className="icon-image"
                      />
                    </a>
                  )}
                  {/* Twitter Icon */}
                  {token.twitter && (
                    <a
                      href={formatUrl(token.twitter)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon-link"
                    >
                      <img
                        src="/twitter.svg"
                        alt="Twitter"
                        className="icon-image"
                      />
                    </a>
                  )}
                  {/* Telegram Icon */}
                  {token.telegram && (
                    <a
                      href={formatUrl(token.telegram)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon-link"
                    >
                      <img
                        src="/telegram.svg"
                        alt="Telegram"
                        className="icon-image"
                      />
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Platform Info as a Button */}
          {token.platformLink && (
            <a
              href={formatUrl(token.platformLink)}
              target="_blank"
              rel="noopener noreferrer"
              className="platform-button"
            >
              {platformIcons[platformKey] && (
                <img
                  src={platformIcons[platformKey]}
                  alt={token.platform}
                  className="platform-icon-image"
                />
              )}
              <span className="platform-name">{token.platform}</span>
            </a>
          )}
        </div>

        {/* Adjusted Padding */}
        <div className="description-container">
          {/* Token Description */}
          {token.description && (
            <div className="token-description">{token.description}</div>
          )}
        </div>

        {/* Token Stats */}
        <div className="token-stats">
          {/* Token Details Section */}
          <div className="token-stat-section">
            <h3 className="token-stat-heading">Token Details</h3>
            {/* Token Address */}
            <div className="token-stat">
              <span className="token-label">Token Address:</span>{' '}
              <button
                onClick={() => navigator.clipboard.writeText(token.tokenAddress)}
                className="copy-button"
              >
                {shortenAddress(token.tokenAddress, true)}
              </button>
              <a
                href={`https://suivision.xyz/coin/${token.tokenAddress}`}
                target="_blank"
                rel="noopener noreferrer"
                className="expand-link"
              >
                <img src="/expand.svg" alt="Expand" className="expand-icon" />
              </a>
            </div>
            <div className="token-stat">
              <span className="token-label">Created:</span>{' '}
              <TimeAgo timestamp={token.timestampMs} />
            </div>
            <div className="token-stat">
              <span className="token-label">Latest Activity:</span>{' '}
              <TimeAgo timestamp={token.latestActivityMs || token.timestampMs} />
            </div>
          </div>

          {/* Developer Info Section */}
          <div className="token-stat-section">
            <h3 className="token-stat-heading">Developer Info</h3>
            <div className="token-stat">
              <span className="token-label">Created By:</span>{' '}
              <button
                onClick={() => navigator.clipboard.writeText(token.createdBy)}
                className="copy-button"
              >
                {shortenAddress(token.createdBy)}
              </button>
              <a
                href={`https://suivision.xyz/account/0x${token.createdBy}`}
                target="_blank"
                rel="noopener noreferrer"
                className="expand-link"
              >
                <img src="/expand.svg" alt="Expand" className="expand-icon" />
              </a>
            </div>
            <div className="token-stat">
              <span className="token-label">Dev Bought at Launch:</span>{' '}
              {token.devBoughtPercentage
                ? token.devBoughtPercentage.toFixed(2)
                : '0.00'}
              %
            </div>
            <div className="token-stat">
              <span className="token-label">Other Tokens Launched by Dev:</span>{' '}
              {token.devLaunchCount}
            </div>
            <div className="token-stat">
              <span className="token-label">Total Transactions by Dev:</span>{' '}
              {token.devTransactionCount}
            </div>
          </div>

          {/* Pool Data Section */}
          <div className="token-stat-section">
            <h3 className="token-stat-heading">Pool Data</h3>
            <div className="token-stat">
              <span className="token-label">Real SUI Reserves:</span>{' '}
              {formatBigNumber(token.realSuiReserves, 9)} SUI
            </div>
            <div className="token-stat">
              <span className="token-label">Bonding Curve %:</span>{' '}
              {token.bondingCurvePercentage != null
                ? token.bondingCurvePercentage.toFixed(2)
                : 'N/A'}
              %
            </div>
          </div>
        </div>

        {/* Less important info */}
        <details className="token-details">
          <summary className="details-summary">
            More Details
            <span className="details-arrow"></span>
          </summary>
          <div className="token-stat">
            <span className="token-label">Package Address:</span>{' '}
            <button
              onClick={() => navigator.clipboard.writeText(token.packageAddress)}
              className="copy-button"
            >
              {shortenAddress(token.packageAddress)}
            </button>
            <a
              href={`https://suivision.xyz/package/0x${token.packageAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              className="expand-link"
            >
              <img src="/expand.svg" alt="Expand" className="expand-icon" />
            </a>
          </div>
          <div className="token-stat">
            <span className="token-label">Creation Tx ID:</span>{' '}
            <button
              onClick={() => navigator.clipboard.writeText(token.transactionId)}
              className="copy-button"
            >
              {shortenAddress(token.transactionId)}
            </button>
            <a
              href={`https://suivision.xyz/txblock/${token.transactionId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="expand-link"
            >
              <img src="/expand.svg" alt="Expand" className="expand-icon" />
            </a>
          </div>
          <div className="token-stat">
            <span className="token-label">Pool ID:</span>{' '}
            <button
              onClick={() => navigator.clipboard.writeText(token.poolId)}
              className="copy-button"
            >
              {shortenAddress(token.poolId)}
            </button>
            <a
              href={`https://suivision.xyz/object/0x${token.poolId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="expand-link"
            >
              <img src="/expand.svg" alt="Expand" className="expand-icon" />
            </a>
          </div>
        </details>
      </li>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.token === nextProps.token;
  }
);

export default App;
